.firebase-emulator-warning {
  display: none;
}

.half-modal {
  align-items: flex-end;

  .modal-wrapper {
    transition: height 420ms;
    height: 30vh !important;
  }

  ion-header ion-toolbar:first-of-type {
    padding-top: 0;
  }
}

.align-alert-message {
  text-align: center;
}
